<template>
    <div>
      <v-overlay :value="overlay" absolute style="z-index:999999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar flat>
        <v-icon left>mdi-robot-outline</v-icon>
        <v-toolbar-title>CHATBOT</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="abrirChatbotDialog(null)" color="blue" small dark
          ><v-icon>mdi-plus</v-icon>Crear</v-btn
        >
        <v-col col="2">
          <v-text-field class="mt-4" label="buscar" v-model="buscarChatbot"></v-text-field>
        </v-col>
               
        
      </v-toolbar>
  
      <v-data-table
        :search="buscarChatbot"
        :headers="headersChatbot"
        :items-per-page="itemsPerPage"
        :items="chatbots"
        class="elevation-1"
        small
        hide-default-footer
      >
        <template v-slot:item.chatbot_id="{ item }">
          <v-btn
            text
            small
            color="green"
            @click="abrirChatbotDialog(item.chatbot_id)"
            ><v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="borrarChatbot(item.chatbot_id)" text small>
            <v-icon color="red" small>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
  
      <!-- dialogo chatbots -->
      <v-dialog v-model="dialogChatbot" persistent width="800">
        <v-form ref="formChatbot">
          <v-card>
            <v-toolbar dark color="blue" height="40" flat>
              <v-icon left>mdi-robot-outline</v-icon>
              Chatbot
              <v-spacer></v-spacer>
              <v-btn small text @click="dialogChatbot = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-text-field
                  v-model="datosChatbot.nombre"
                  label="Nombre"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col md="12" class="py-0 my-0">
                <v-text-field
                  v-model="datosChatbot.model"
                  label="Modelo"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col md="12" class="py-0 my-0">
                <v-textarea
                  v-model="datosChatbot.system_content"
                  label="System content"
                  :rules="requiredRule"
                ></v-textarea>
              </v-col>

              <v-col md="12" class="py-0 my-0">
                <v-textarea
                  rows="2"
                  v-model="datosChatbot.telefonos"
                  label="Telefonos"
                  :rules="requiredRule"
                ></v-textarea>
              </v-col>
              
              <v-col md="12" class="py-0 my-0">                
                <v-select
                  v-model="datosChatbot.device_id"
                  label="Dispositivo"
                  :items="dispositivos"
                  item-value="device_id"
                  item-text="description"
                  :rules="requiredRule"
                ></v-select>
              </v-col>
               
            </v-row>
  
            <v-toolbar>
              <v-btn color="primary" small @click="guardarChatbot">
                <v-icon>mdi-content-save</v-icon>Guardar
              </v-btn>
              <v-divider vertical class="px-2" inset></v-divider>
              <v-btn text small @click="dialogChatbot = false">
                <v-icon>mdi-content-cancel</v-icon>Cancelar
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-form>
      </v-dialog>
      
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import Vue from 'vue'
  
  export default {
    name: "ChatbotAdmin",
    data: () => ({
      show: false,
  
      requiredRule: [(v) => !!v || "El campo es requerido"],
      options: {},
      setfilters: [],
      selected: [],
      users: [],
      totalItems: 1,
      currentPage: 1,
      itemsPerPage: 50,
      pageCount: 1,
      overlay: false,
      buscarChatbot: "",
      incluirInactivas: false,
  
      required: [(v) => !!v || "El campo es requerido"],
  
      headersChatbot: [
        { text: "ID", align: "start", value: "chatbot_id" },
        { text: "Nombre", align: "start", value: "nombre" },
        { text: "Descripcion", align: "start", value: "descripcion" },
        { text: "Estado", align: "start", value: "estado" },
        { text: "Tipo", align: "start", value: "tipo" },
        
      ],
      chatbots: [],
      dialogChatbot: false,
      datosChatbot: {
        chatbot_id: "",
        nombre: "",
        descripcion: "",
        estado: "",
        tipo: "",
        model:"",
        system_content:""
      },
      
      crearChatbot: false,
      dispositivos: [{"device_id" : 1, "description" :"IHEALTHY CONFIRMACIONES (593992451928)"},{"device_id":11,"description":"IHEALTHY CITAS Y LEADS (593989487029)"}]
       
    }),
    computed: {
      ...mapState("master", ["loadingBtn", "companies"]),
      ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
      ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
      ]),
      ...mapActions("master", ["requestApi", "alertNotification"]),
      ...mapActions("access", []),
  
      cargarListaChatbots() {
        this.setLoadingTable(true);
        this.overlay = true;
        this.setUrl("chatbot");
        this.requestApi({
          method: "GET",
          data: {
            page: this.currentPage,
            page_count: this.pageCount,
            page_size: this.itemsPerPage,
          },
        })
          .then((res) => {
            //console.log(res.data);
            this.chatbots = res.data._embedded.chatbot;
            this.pageCount = res.data.page_count;
            this.currentPage = res.data.page;
            this.totalItems = res.data.total_items;
            this.overlay = false;
          })
          .then(() => {
            this.setLoadingTable(false);
            this.overlay = false;
          });
      },
      abrirChatbotDialog(chatbotId) {
        this.datosChatbot = {
          chatbot_id: "",
          nombre: "",
        };
        this.dialogChatbot = true;
        this.crearChatbot = true;
        if (chatbotId != null) {
          this.crearChatbot = false;
          this.cargarDatosChatbot(chatbotId);
        }
      },
  
      cargarDatosChatbot(id) {
        this.setLoadingTable(true);
        this.setUrl("chatbot/" + id);
        this.requestApi({
          method: "GET",
          data: {},
        })
          .then((res) => {
            //console.log(res.data.detail);
            /*this.datosChatbot.chatbot_id = res.data.detail.chatbot.chatbot_id;
            this.datosChatbot.nombre = res.data.detail.chatbot.nombre;
            this.datosChatbot.estado = res.data.detail.chatbot.estado;
            this.datosChatbot.descripcion = res.data.detail.chatbot.descripcion;
            this.datosChatbot.model = res.data.detail.chatbot.model;*/
            this.datosChatbot = res.data.detail.chatbot;
            
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
      guardarChatbot() {
        if (!this.$refs.formChatbot.validate()) {
          return false;
        }
        this.setLoadingTable(true);
        this.setUrl("chatbot");
        this.requestApi({
          method: "POST",
          data: {
            accion:
              this.crearChatbot == true ? "crearChatbot" : "modificarChatbot",
            datosChatbot: this.datosChatbot,
          },
        })
          .then((res) => {
            //console.log(res.data.detail);
  
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
            if (this.crearChatbot == true) {
              //this.cargarDatosChatbot(res.data.detail.chatbot_id);
              this.cargarListaChatbots();
              this.dialogChatbot = false;
            } else {
              this.cargarListaChatbots();
              this.dialogChatbot = false;
            }
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
          
      
      borrarChatbot(chatbotId) {
        Vue.swal({
              html: "Está seguro de eliminar este chatbot ?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Cerrar',
              ...this.paramAlertQuestion
          }).then( result => {
              
              if (result.isConfirmed) {
                this.setLoadingTable(true);
                this.setUrl("chatbot");
                this.requestApi({
                  method: "POST",
                  data: {
                    accion: "borrarChatbot",
                    chatbot_id: chatbotId,
                  },
                })
                  .then((res) => {
  
                    this.alertNotification({
                      param: {
                        html: res.data.detail.msg,
                      },
                    });
                    this.cargarListaChatbots();
                  })
                  .then(() => {
                    this.setLoadingTable(false);
                  });
  
                }
              })
      },
      
  
      handlePageChange(value) {
        this.cargarListaChatbots();
      },
    },
    mounted() {
      this.cargarListaChatbots();
      this.setTitleToolbar("CHATBOTS");
    },
  };
  </script>
  